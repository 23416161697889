/* eslint-disable no-shadow */
import Vue from 'vue';
import { API, graphqlOperation } from 'aws-amplify';
import GQL from '../graphql';

const state = () => ({
  name: 'Stats',
  list: [],
  listGlobal: [],
  hasNextPage: true,
  pageInfo: {},
  loadings: {},
  count: 0,
});

const mutations = {
  setList: (state, payload) => {
    const data = payload || [];
    Vue.set(state, 'list', data);
  },
  setLoadings: (state, payload) => {
    Vue.set(state.loadings, payload.type, payload.value);
  },
  setCount: (state, payload) => {
    Vue.set(state, 'count', payload);
  },
  clearPagin: (state) => {
    Vue.set(state, 'hasNextPage', true);
    Vue.set(state, 'pageInfo', {});
  },
};

const actions = {
  async fetch({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.GetEpisodesDataToCharts, {
          input: payload,
        }),
      );
      const { data, totalCount } = response.data.getEpisodesDataToCharts;
      commit('setList', data);
      commit('setCount', totalCount);
      return data;
    } catch (error) {
      return { ok: false, message: error };
    }
  },
};

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
};
