<template>
  <svg id="Iconly_Curved_Document" data-name="Iconly/Curved/Document" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
   <g id="_3_User" data-name="3 User" transform="translate(1 3)">
    <path id="Stroke_1" data-name="Stroke 1" d="M6.006,6.122C2.767,6.122,0,5.632,0,3.67S2.749,0,6.006,0c3.239,0,6.006,1.692,6.006,3.653S9.263,6.122,6.006,6.122Z" transform="translate(4.966 11.246)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
    <path id="Stroke_3" data-name="Stroke 3" d="M3.85,7.7a3.837,3.837,0,1,0-.027,0Z" transform="translate(7.122 0.75)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
    <path id="Stroke_5" data-name="Stroke 5" d="M.166,5.643a2.9,2.9,0,0,0,2.149-2.8A2.9,2.9,0,0,0,0,0" transform="translate(17.196 1.749)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
    <path id="Stroke_7" data-name="Stroke 7" d="M0,0C1.754,0,3.252,1.189,3.252,2.251a1.64,1.64,0,0,1-1.3,1.49" transform="translate(17.943 10.544)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
    <path id="Stroke_9" data-name="Stroke 9" d="M2.15,5.643A2.9,2.9,0,0,1,2.315,0" transform="translate(2.434 1.749)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
    <path id="Stroke_11" data-name="Stroke 11" d="M3.252,0C1.5,0,0,1.189,0,2.251a1.64,1.64,0,0,0,1.3,1.49" transform="translate(0.75 10.544)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
  </g>
</svg>
</template>

<script>
export default {
  name: 'CustomersIcon',
  props: {
    title: {
      type: String,
    },
  },
};
</script>
