/* eslint-disable no-shadow */
import Vue from 'vue';
import { API, graphqlOperation } from 'aws-amplify';
import GQL from '../graphql';

const state = {
  name: 'Settings',
  voiceSettings: {},
  playerSettings: {},
  regionList: [],
  hasNextPage: true,
  pageInfo: {},
  voiceList: [],
  pluginLink: null,
};

const mutations = {
  setVoices: (state, payload) => {
    Vue.set(state, 'voiceList', payload);
  },
  setPluginLink: (state, payload) => {
    Vue.set(state, 'pluginLink', payload);
  },
  setVoiceSettings: (state, payload) => {
    Vue.set(state, 'voiceSettings', payload);
  },
  setPlayerSettings: (state, payload) => {
    Vue.set(state, 'playerSettings', payload);
  },
  setLoadings: (state, payload) => {
    Vue.set(state.loadings, payload.type, payload.value);
  },
};

const actions = {
  async getPluginLink({ commit }) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.GetPluginLink),
      );
      const { getPluginLink } = response.data;
      commit('setPluginLink', getPluginLink.url);
      return getPluginLink;
    } catch (error) {
      return { ok: false, message: error.errors };
    }
  },
  async fetchVoiceSettings({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.EnumVoices, {
          input: {
            languageCode: payload,
          },
        }),
      );
      const { settings } = response.data;
      commit('setVoiceSettings', settings);
      return settings;
    } catch (error) {
      return { ok: false, message: error.errors };
    }
  },
  async fetchPlayerSettings({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.EnumPlayers, {
          input: {
            languageCode: payload,
          },
        }),
      );
      const { settings } = response.data;
      commit('setPlayerSettings', settings);
      return settings;
    } catch (error) {
      return { ok: false, message: error.errors };
    }
  },
  async updateVoiceSettings({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.UpdateEpisodeTemplatePartById,
          { input: payload },
        ),
      );
      const { updateEpisodeTemplatePartById } = response.data;
      return updateEpisodeTemplatePartById;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'feedItem', value: false });
    }
  },
  async updatePlayerSettings({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.RedeemCoupon, {
          input: payload,
        }),
      );
      const { data } = response;
      commit('setPlayerSettings', data.voices);
      return data;
    } catch (error) {
      return { ok: false, message: error.errors };
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
