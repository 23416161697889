<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="250" width="250"><path stroke="CurrentFill" stroke-linecap="round" stroke-linejoin="round" d="M7 3.48096V10.5187"></path><path stroke="CurrentFill" stroke-linecap="round" stroke-linejoin="round" d="M4.05444 5.41431V8.58517"></path><path stroke="CurrentFill" stroke-linecap="round" stroke-linejoin="round" d="M9.94556 5.41431V8.58517"></path><path stroke="CurrentFill" stroke-linecap="round" d="M10.0068 0.942627C10.3777 0.982575 10.7452 1.0254 11.1091 1.06757C12.0686 1.17877 12.8342 1.94387 12.9419 2.90375C12.9825 3.26529 13.0234 3.63042 13.0615 3.99886"></path><path stroke="CurrentFill" stroke-linecap="round" d="M10.0068 13.0574C10.3777 13.0174 10.7452 12.9746 11.1091 12.9324C12.0686 12.8212 12.8342 12.0561 12.9419 11.0962C12.9825 10.7347 13.0234 10.3696 13.0615 10.0011"></path><path stroke="CurrentFill" stroke-linecap="round" d="M3.99316 0.942627C3.62232 0.982575 3.2548 1.0254 2.89092 1.06757C1.93143 1.17877 1.16584 1.94387 1.05807 2.90375C1.01748 3.26529 0.976557 3.63042 0.938516 3.99886"></path><path stroke="CurrentFill" stroke-linecap="round" d="M3.99316 13.0574C3.62232 13.0174 3.2548 12.9746 2.89092 12.9324C1.93143 12.8212 1.16584 12.0561 1.05807 11.0962C1.01748 10.7347 0.976557 10.3696 0.938516 10.0011"></path></svg>
</template>

<script>
export default {
  name: 'VoiceIcon',
  props: {
    title: {
      type: String,
      default: 'voice id user',
    },
  },
};
</script>
