<template>
<div class="bg-hm-black min-h-screen ">
  <div class="max-w-full text-center mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:flex lg:justify-between ">
    <div class="container max-w-6xl mx-auto">
      <div class="mt-10 w-full max-w-sm mx-auto">
        <div class="mt-1.5 relative text-center">
        </div>
      </div>
      <div class="mt-6">
        <h2 class="text-4xl font-medium text-yellow-500 sm:text-7xl sm:tracking-tight lg:text-9xl">401</h2>
        <p class="mt-5 text-xl text-white">
          {{$t('ui.notAuthorized')}}
        </p>
      </div>
    </div>
  </div>
</div>

</template>
<script>
export default {
  data() {
    return {
    };
  },
  mounted() {
    // console.log('-> 404');
  },
  methods: {
  },
};
</script>

<style scoped>
.bg-hm-black {
  background-image: url('../assets/images/bg.png');
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>
