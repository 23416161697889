<template>
  <div class="plugin-guide px-4 sm:px-11">
    <svg
      class="mx-auto mt-4"
      xmlns="http://www.w3.org/2000/svg" width="94.861" height="98.304" viewBox="0 0 94.861 98.304">
      <g id="plugin-icon" transform="translate(7779 2298)">
        <path id="Subtraction_5" data-name="Subtraction 5" d="M8.3,20.938a3.279,3.279,0,0,1-1.228-.179c-.674-.278-.778-.994-.863-1.57-.051-.346-.094-.644-.259-.729A8.949,8.949,0,0,1,4.209,17.08,6.255,6.255,0,0,0,3,16.1a.988.988,0,0,0-.41-.09A1.514,1.514,0,0,0,2,16.155l-.063.026a1.1,1.1,0,0,1-.394.118.336.336,0,0,1-.3-.2C.41,14.687.16,11.756.085,10.135-.251,9.281.452,8.794,1.2,8.278c.6-.417,1.224-.848,1.272-1.494A4.5,4.5,0,0,0,2.017,4.9c-.307-.769-.573-1.433-.078-1.874C2.9,2.173,5.141.177,6.809.008A1.422,1.422,0,0,1,6.958,0c.686,0,.936.554,1.177,1.089a1.686,1.686,0,0,0,.686.931,3.273,3.273,0,0,0,1.355.227c.4,0,.835-.036,1.182-.065l.051,0c.373-.032.724-.062,1.036-.062a1.749,1.749,0,0,1,.855.156.253.253,0,0,0,.124.038c.21,0,.305-.379.365-.71.014-.076.026-.153.038-.225.043-.26.079-.485.146-.485a.053.053,0,0,1,.036.019,12.826,12.826,0,0,0,1.445,1.057c1.178.791,2.513,1.688,2.843,2.543.254.654-.256,1.43-.665,2.053a3.212,3.212,0,0,0-.394.7,3.352,3.352,0,0,0,.092,2.03,2.72,2.72,0,0,1,.158.845c-.011.341.861.6,1.562.816.49.148.914.276.888.389a12.5,12.5,0,0,0-.139,2.275c-.01,1.575-.021,3.2-.761,3.915a.381.381,0,0,1-.275.1,2.823,2.823,0,0,1-1.081-.4l-.142-.075a2.4,2.4,0,0,0-.764-.323.145.145,0,0,0-.067.014c-.428.231-.8.443-1.16.649A9.983,9.983,0,0,1,12.579,18.8a.226.226,0,0,0-.153.107c-.115.2,0,.6.108.948.077.26.143.485.092.56a.059.059,0,0,1-.047.026c-.344.033-.8.1-1.283.177A21.223,21.223,0,0,1,8.3,20.938Zm.972-13.8a3.5,3.5,0,1,0,3.5,3.5A3.5,3.5,0,0,0,9.274,7.142Z" transform="translate(-7721.274 -2229.642)" fill="#fab327" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
        <g id="Group_5614" data-name="Group 5614" transform="translate(-8016.948 -2612.877)">
          <g id="Group_5608" data-name="Group 5608">
            <path id="Path_44131" data-name="Path 44131" d="M278.155,413.181c-.762,0-1.528-.026-2.3-.093a35.961,35.961,0,0,1-9.493-2.6c-.789-.3-1.579-.594-2.375-.876l-4.694-1.666a4.223,4.223,0,0,1-1.451-.76c-1.635-1.435-1.83-4.69-1.906-8.481-.015-.776-.028-1.447-.071-1.911-.348-3.8-.638-7.666-.861-11.492-.447-7.668-.641-15.447-.578-23.129-1.017-.98-2.049-2-3.094-3.024-3.018-2.967-6.137-6.036-9.245-8.607q-1.239-1.026-2.488-2.04l-.08-.065a2.715,2.715,0,0,0-.8-.523,1.061,1.061,0,0,1,.12-2.07,89.619,89.619,0,0,0,12.79-3.335,1.062,1.062,0,0,1,1.012.2c6.512,5.568,12.974,11.694,19.211,18.212,14.373.22,30.927-.441,49.209-1.974,1.577-1.74,3.214-3.617,4.8-5.436,1.684-1.931,3.425-3.928,5.1-5.766a1.061,1.061,0,1,1,1.569,1.43c-1.659,1.82-3.392,3.808-5.068,5.731s-3.432,3.936-5.11,5.778a1.061,1.061,0,0,1-.7.343c-18.707,1.58-35.625,2.255-50.282,2.009a1.064,1.064,0,0,1-.75-.329c-6.139-6.436-12.5-12.49-18.912-18-3.106.988-6.566,2.037-10.017,2.763q.876.714,1.746,1.434c3.178,2.629,6.331,5.73,9.38,8.729,1.152,1.133,2.289,2.251,3.407,3.323a1.062,1.062,0,0,1,.326.776c-.075,7.783.116,15.671.57,23.444.221,3.8.51,7.645.856,11.422.049.539.063,1.245.08,2.061.041,2.1.119,5.994,1.183,6.929a2.6,2.6,0,0,0,.76.355l4.694,1.665c.809.287,1.612.589,2.413.89a33.85,33.85,0,0,0,8.931,2.471,40.536,40.536,0,0,0,5.683-.047l36.108-1.985c1.782-.1,3.064-.553,3.517-1.248a2.325,2.325,0,0,0-.046-2.082,1.061,1.061,0,1,1,1.987-.746,4.275,4.275,0,0,1-.163,3.988c-.864,1.323-2.606,2.066-5.178,2.207l-36.108,1.985C280.635,413.113,279.4,413.181,278.155,413.181Z" fill="#1f2532"/>
          </g>
          <g id="Group_5609" data-name="Group 5609">
            <path id="Path_44132" data-name="Path 44132" d="M322.39,409.413a1.061,1.061,0,0,1-1.061-1.037l-1.068-48.392a1.061,1.061,0,0,1,2.122-.047l1.068,48.392A1.065,1.065,0,0,1,322.39,409.413Z" fill="#1f2532"/>
          </g>
          <g id="Group_5610" data-name="Group 5610">
            <path id="Path_44133" data-name="Path 44133" d="M273.493,408.1a1.06,1.06,0,0,1-1.056-.972c-1.246-14.816-1.881-29.867-1.886-44.736a1.061,1.061,0,1,1,2.122,0c.005,14.809.637,29.8,1.878,44.558a1.061,1.061,0,0,1-.968,1.147Z" fill="#1f2532"/>
          </g>
          <g id="Group_5611" data-name="Group 5611">
            <path id="Path_44134" data-name="Path 44134" d="M255.75,362.478a1.061,1.061,0,0,1-.087-2.119,88.9,88.9,0,0,1,14.757,0,1.061,1.061,0,0,1-.177,2.115,86.915,86.915,0,0,0-14.4,0C255.809,362.477,255.779,362.478,255.75,362.478Z" fill="#1f2532"/>
          </g>
          <g id="Group_5612" data-name="Group 5612">
            <path id="Path_44135" data-name="Path 44135" d="M302.959,361.614a1.061,1.061,0,0,1-.736-1.825c3.78-3.642,7.682-7.266,11.6-10.77a1.036,1.036,0,0,1,.719-.271,134.279,134.279,0,0,0,15.871-1.153l1.01-.113a1.061,1.061,0,0,1,.235,2.109l-1.008.113a138.447,138.447,0,0,1-15.716,1.169c-3.794,3.4-7.573,6.915-11.236,10.444A1.061,1.061,0,0,1,302.959,361.614Z" fill="#1f2532"/>
          </g>
          <g id="Group_5613" data-name="Group 5613">
            <path id="Path_44136" data-name="Path 44136" d="M289.6,351.941a1.058,1.058,0,0,1-.683-.249,59.566,59.566,0,0,0-5.571-4.148c-2.394-1.573-4.952-3-7.425-4.382l-8.461-4.723a1.061,1.061,0,0,1,.379-1.979,54.811,54.811,0,0,1,5.636-.346c1.779-.048,3.609-.1,5.359-.321a117.652,117.652,0,0,0-1.887-18.4,1.063,1.063,0,0,1,1-1.237c2.9-.116,5.969-.381,8.938-.638,2.995-.259,6.093-.527,9.042-.644a1.09,1.09,0,0,1,.812.329,1.064,1.064,0,0,1,.287.828,135.989,135.989,0,0,0-.339,20.832c.67-.112,1.365-.245,2.085-.382a32.727,32.727,0,0,1,6.3-.789,1.061,1.061,0,0,1,.649,1.887,167.08,167.08,0,0,0-15.361,14.034A1.055,1.055,0,0,1,289.6,351.941ZM271.564,338.3l5.394,3.012c2.507,1.4,5.1,2.846,7.556,4.461a61.564,61.564,0,0,1,5.009,3.668c3.817-3.913,7.917-7.712,12.23-11.333-.853.134-1.721.3-2.577.464-.949.181-1.924.367-2.856.5a1.244,1.244,0,0,1-.235.159,1.154,1.154,0,0,1-1.264-.141,1.179,1.179,0,0,1-.4-1.239,1.05,1.05,0,0,1,.184-.352,137.252,137.252,0,0,1,.2-20.447c-2.537.133-5.176.361-7.738.583-2.59.224-5.256.454-7.836.588a113,113,0,0,1,1.724,18.488,1.062,1.062,0,0,1-.886,1.044,47.243,47.243,0,0,1-6.542.48C272.883,338.253,272.223,338.271,271.564,338.3Z" fill="#1f2532"/>
          </g>
          <path id="Path_44137" data-name="Path 44137" d="M304.617,405.554a16.47,16.47,0,0,1-2.6-.218.921.921,0,0,1-.772-.833c-.067-.793-.11-1.65-.132-2.656a7.264,7.264,0,0,1-2.695-2.033c-.2.1-.4.207-.613.316-.3.154-.589.307-.886.452a.922.922,0,0,1-1.242-.445,20.628,20.628,0,0,1-1.866-6.8.924.924,0,0,1,.537-.918,7.182,7.182,0,0,1,1.964-.505,9.48,9.48,0,0,1,1.207-3.688A11.783,11.783,0,0,1,296.279,387a.921.921,0,0,1,.045-1.24,12.774,12.774,0,0,1,6.412-3.665.92.92,0,0,1,1.01.476c.327.628.642,1.283,1,2.07a10.129,10.129,0,0,1,2.913.42q.176-.319.348-.639c.229-.423.458-.846.705-1.269a.919.919,0,0,1,1.142-.391,11.032,11.032,0,0,1,5.886,5.051.922.922,0,0,1-.3,1.17l-.423.288c-.459.312-.9.612-1.307.934a14.62,14.62,0,0,1,.565,2.729,16.254,16.254,0,0,1,1.7.333.921.921,0,0,1,.7.835c.033.5.049.979.049,1.434a11.924,11.924,0,0,1-1.291,5.868.922.922,0,0,1-1.055.442,12.775,12.775,0,0,1-2.06-.837,13.378,13.378,0,0,1-2.8,1.541l.233,1.052a.921.921,0,0,1-.526,1.042A11.31,11.31,0,0,1,304.617,405.554Zm-1.592-1.939a10.948,10.948,0,0,0,4.752-.384l-.235-1.06a.92.92,0,0,1,.6-1.068,11.652,11.652,0,0,0,3.487-1.883.922.922,0,0,1,.969-.118l.47.223c.372.178.732.35,1.087.495a11.23,11.23,0,0,0,.729-4.282c0-.2,0-.414-.011-.63a11.042,11.042,0,0,0-1.524-.213.923.923,0,0,1-.862-.891,12.547,12.547,0,0,0-.716-3.606.921.921,0,0,1,.254-.979,15.313,15.313,0,0,1,1.665-1.273A9.5,9.5,0,0,0,309.9,384.8q-.138.252-.273.5c-.239.44-.477.88-.734,1.319a.919.919,0,0,1-1.079.411,9.058,9.058,0,0,0-3.581-.524.928.928,0,0,1-.919-.547c-.319-.726-.6-1.333-.873-1.889a10.905,10.905,0,0,0-4.153,2.364c.252.238.521.47.8.709l.235.2a.921.921,0,0,1,.158,1.214,7.646,7.646,0,0,0-1.371,4.166.924.924,0,0,1-.767.894c-.2.034-.4.063-.6.092-.357.052-.7.1-1.02.179a18.7,18.7,0,0,0,1.246,4.6c.447-.234.909-.474,1.385-.684a.92.92,0,0,1,1.169.379,5.233,5.233,0,0,0,2.8,2.166.921.921,0,0,1,.616.857C302.954,402.12,302.981,402.9,303.025,403.615Z" fill="#1f2532"/>
          <path id="Path_44138" data-name="Path 44138" d="M305.2,398.535a4.8,4.8,0,0,1-.826-9.531,5.523,5.523,0,0,1,.957-.072,4.69,4.69,0,0,1,4.733,4.387,5.125,5.125,0,0,1-1.38,3.753A4.687,4.687,0,0,1,305.2,398.535Zm-.524-7.713a3.047,3.047,0,0,0-2.481,3.018,3.007,3.007,0,0,0,2.987,2.852,2.908,2.908,0,0,0,2.166-.89,3.255,3.255,0,0,0,.876-2.38,2.829,2.829,0,0,0-2.919-2.647A3.334,3.334,0,0,0,304.673,390.822Z" fill="#1f2532"/>
        </g>
      </g>
    </svg>
    <h1 class="text-center font-bold text-3xl my-4">{{$t('ui.guideTitle')}}</h1>
    <ol class="steps list-decimal list-outside px-6 mt-8 space-y-16">
      <li class="text-xl">
        <div class="step-title text-xl">{{$t('ui.stepOneTitle')}}</div>
        <div class="step-desc text-gray-500 text-xs">{{$t('ui.stepOneDesc')}}</div>
        <div class="step-content mt-3">
          <a :href="pluginUrl" target="_blank" class="items-center justify-center border border-transparent font-medium rounded-lg shadow-sm focus:outline-none focus:ring-current focus:ring-current cursor-pointer transition duration-600 ease-in-out inline-block mx-auto text-white bg-black hover:bg-blue-700 px-6 py-3 text-sm">{{$t('ui.downloadPlugin')}}</a>
        </div>
      </li>
      <li class="text-xl">
        <div class="step-title text-xl">{{$t('ui.step2Title')}}</div>
        <div class="step-desc text-gray-500 text-xs">{{$t('ui.step2Desc')}}</div>
        <div class="step-content mt-3">
          <img src="../assets/images/step-1.png" alt="">
          <!-- <image-dialog thumb="../assets/images/step-1.png" full="../assets/images/step-1.png" :full-width="945" :full-height="600"></image-dialog> -->
        </div>
      </li>
      <li class="text-xl">
        <div class="step-title text-xl">{{$t('ui.step3Title')}}</div>
        <div class="step-desc text-gray-500 text-xs">{{$t('ui.step3Desc')}}</div>
        <div class="step-content mt-3">
          <img src="../assets/images/step-2.png" alt="">
        </div>
      </li>
      <li class="text-xl">
        <div class="step-title text-xl">{{$t('ui.step4Title')}}</div>
        <div class="step-desc text-gray-500 text-xs" v-html="$t('ui.step4Desc', {token: token})">{{}}</div>
        <div class="rounded-md bg-blue-50 p-2 mt-2">
          <div class="flex">
            <div class="ml-2 flex-1 md:flex md:justify-between">
              <p class="text-xs text-blue-700 font-bold">
                <span class="select-none">{{$t('ui.apikey')}}: </span>
                <span>{{token}}</span>
              </p>
              <p class="mt-3 text-xs md:mt-0 md:ml-6 mr-2">
                <a href="#" @click="copy(token)" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">{{$t('ui.copyToClipboard')}}</a>
              </p>
            </div>
          </div>
        </div>
        <div class="step-content mt-3">
          <img src="../assets/images/step-3.png" alt="">
        </div>
      </li>
      <li class="text-xl">
        <div class="step-title text-xl">{{$t('ui.step5Title')}}</div>
        <div class="step-desc text-gray-500 text-xs">{{$t('ui.step5Desc')}}</div>
        <div class="step-content mt-3">
          <img src="../assets/images/step-4.png" alt="">
        </div>
      </li>
      <li class="text-xl">
        <div class="step-title text-xl">{{$t('ui.step6Title')}}</div>
        <div class="step-desc text-gray-500 text-xs">{{$t('ui.step6Desc')}}</div>
        <div class="step-content mt-3">
          <img src="../assets/images/step-5.png" alt="">
        </div>
      </li>

    </ol>
    <div class="step-title text-xl text-center font-bold mt-6">{{$t('ui.step7Title')}}</div>
    <div class="step-desc text-gray-500 text-xs text-center max-w-3/4 mx-auto">{{$t('ui.step7Desc')}}</div>
    <div class="text-center mt-4 mb-12">
      <router-link :to="articleLink || '/articles/none/list'" class="items-center justify-center border border-transparent font-medium rounded-lg shadow-sm focus:outline-none focus:ring-current focus:ring-current cursor-pointer transition duration-600 ease-in-out inline-block mx-auto text-white bg-black hover:bg-blue-700 px-6 py-3 text-sm">{{$t('ui.goToArticles')}}</router-link>
    </div>
  </div>
</template>
<script>
// import ImageDialog from './ImageDialog.vue';
// import APP_CONFIG from '../config';

export default {
  // components: {
  //   ImageDialog,
  // },
  props: [
    'token',
    'articleLink',
    'pluginUrl',
  ],
  data() {
    return {
    };
  },
  methods: {
    copy() {
      const el = document.createElement('textarea');
      el.value = this.token;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.codeCopied'),
      });
    },
  },
};
</script>
<style scoped>
.plugin-guide {
  background-image: url('../assets/images/guide-bg.png');
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: -24px;
  margin-top: -30px;
  padding-top: 30px;
}
</style>
