<template>
  <svg id="Iconly_Curved_Document" data-name="Iconly/Curved/Document" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
   <g id="Discount" transform="translate(2 2)">
    <path id="Stroke_1" data-name="Stroke 1" d="M0,9.25C0,2.313,2.313,0,9.25,0S18.5,2.313,18.5,9.25,16.187,18.5,9.25,18.5,0,16.187,0,9.25Z" transform="translate(0.75 0.75)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
    <path id="Stroke_3" data-name="Stroke 3" d="M0,5.14,5.14,0" transform="translate(7.43 7.43)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
    <path id="Stroke_15" data-name="Stroke 15" d="M.5.5H.5" transform="translate(12 12)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
    <path id="Stroke_15-2" data-name="Stroke 15" d="M.5.5H.5" transform="translate(7 7)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
  </g>
</svg>
</template>

<script>
export default {
  name: 'CouponsIcon',
  props: {
    title: {
      type: String,
    },
  },
};
</script>
