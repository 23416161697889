<template>
  <div class="plugin-guide px-4 sm:px-11">
    <h1 class="text-center font-bold text-3xl my-4">{{$t('ui.publishCostGuideTitle')}}</h1>
    <div class="step-desc text-gray-500 text-sm text-center max-w-3/4 mx-auto">{{$t('ui.publishCostGuideLead')}}</div>
  </div>
</template>
<script>
// import ImageDialog from './ImageDialog.vue';
// import APP_CONFIG from '../config';

export default {
  // components: {
  //   ImageDialog,
  // },
  props: [
    'show',
  ],
  data() {
    return {
    };
  },
  methods: {
    close() {
      this.$emit('update:show', false);
    },
  },
};
</script>
<style scoped>
.plugin-guide {
  background-image: url('../assets/images/guide-bg.png');
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: -24px;
  margin-top: -30px;
  padding-top: 30px;
}
</style>
