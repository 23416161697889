<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="250" width="250"><path stroke="CurrentFill" d="M1.18227 11.887C1.23381 12.3672 1.6148 12.7479 2.0949 12.8004C3.66383 12.9719 5.30719 13.2076 6.99997 13.2076C8.69275 13.2076 10.3361 12.9719 11.905 12.8004C12.3851 12.7479 12.7661 12.3672 12.8177 11.887C12.9855 10.3238 13.2075 8.68651 13.2075 7C13.2075 5.31349 12.9855 3.67619 12.8177 2.11299C12.7661 1.63279 12.3851 1.25212 11.905 1.19963C10.3361 1.02811 8.69275 0.79245 6.99997 0.79245C5.30719 0.79245 3.66383 1.0281 2.0949 1.19963C1.6148 1.25212 1.23381 1.63279 1.18227 2.11299C1.01448 3.67619 0.792419 5.31349 0.792419 7C0.792419 8.68651 1.01448 10.3238 1.18227 11.887Z"></path><path stroke="CurrentFill" stroke-linecap="round" stroke-linejoin="round" d="M4.85274 6.99997C4.85274 7.56275 4.94905 8.37134 5.04174 9.01929C5.12412 9.59517 5.69326 9.96444 6.24135 9.76944C7.55747 9.30117 8.78443 8.58133 9.62875 7.50119C9.85945 7.20606 9.85946 6.79387 9.62878 6.49873C8.78453 5.41857 7.55753 4.69874 6.24141 4.2305C5.69334 4.03551 5.12423 4.40474 5.04181 4.9806C4.94909 5.62855 4.85274 6.43718 4.85274 6.99997Z"></path></svg>
</template>

<script>
export default {
  name: 'PlayerIcon',
  props: {
    title: {
      type: String,
      default: 'video player adjust',
    },
  },
};
</script>
