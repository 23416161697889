<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="250" width="250"><path stroke="CurrentFill" stroke-linejoin="round" d="M8.06567 0.79541V4.0008C8.06567 4.55309 8.51274 4.9789 9.06479 4.99485C10.4535 5.03496 12.4966 5.40942 12.4966 7.90387"></path><path stroke="CurrentFill" d="M12.3738 11.1239C12.4565 9.80571 12.5 8.43973 12.5 7.04004C12.5 6.67582 12.4971 6.31389 12.4912 5.95449C12.4786 5.17471 12.2307 4.41261 11.7522 3.79683C11.0279 2.86491 10.3705 2.2065 9.3771 1.43981C9.05979 1.19491 8.67041 1.06204 8.26969 1.05328C7.86786 1.0445 7.44825 1.04004 7 1.04004C5.64305 1.04004 4.54845 1.08091 3.4157 1.15879C2.45206 1.22505 1.68673 1.99218 1.62622 2.9562C1.54349 4.27437 1.5 5.64034 1.5 7.04004C1.5 8.43973 1.54349 9.80571 1.62622 11.1239C1.68673 12.0879 2.45206 12.855 3.4157 12.9213C4.54845 12.9992 5.64305 13.04 7 13.04C8.35695 13.04 9.45155 12.9992 10.5843 12.9213C11.5479 12.855 12.3133 12.0879 12.3738 11.1239Z"></path></svg>
</template>

<script>
export default {
  name: 'ArticlesIcon',
  props: {
    title: {
      type: String,
    },
  },
};
</script>
