import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
import ui from './ui';
import website from './website';
import admin from './admin';
import billing from './billing';
import utils from './utilities';
import settings from './settings';
import customer from './customer';
import alias from './alias';
import stats from './stats';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    ui,
    website,
    admin,
    utils,
    billing,
    settings,
    customer,
    alias,
    stats,
  },
});
