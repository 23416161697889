<template>
  <div class="mt-1 relative" v-click-outside="close" :class="{'z-20': show}">
    <button
      @click="show = !show"
      type="button" class="w-80 relative bg-white border border-gray-300 rounded-lg pl-3 pr-10 py-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
      <span class="w-full inline-flex truncate">
        {{selected.displayName || selected.name|| $t('ui.selectVoice')}}
      </span>
      <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <!-- Heroicon name: solid/selector -->
        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </span>
    </button>

    <!--
      Select popover, show/hide based on select state.

      Entering: ""
        From: ""
        To: ""
      Leaving: "transition ease-in duration-100"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <ul
      v-show="show"
      class="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
      <!--
        Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

        Highlighted: "text-white bg-blue-600", Not Highlighted: "text-gray-900"
      -->
      <slot></slot>
      <li
        v-for="(item, index) in itemsWithId"
        :key="item.sampleFilename + item.ttsVoiceEngine"
        class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9" id="listbox-option-0" role="option">
        <div class="flex">
          <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
          <span class="font-normal truncate"
            :class="{'font-normal': voiceId(selected) !== voiceId(item), 'font-semibold': voiceId(selected) === voiceId(item)}">
            <svg @click="previewAudio(item, index)"
              class="h-7 w-7 inline-block cursor-pointer -mt-0.5 hover:text-hm-secondary"
              :class="{'animate-pulse text-hm-brand': playing === index}"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
            </svg>
            <span
              :class="{'animate-pulse text-hm-brand': playing === index}">{{item.displayName || item.name}}
              <span class="text-xs bg-yellow-500 inline-block text-white py-0 px-1 rounded" v-show="item.ttsVoiceEngine === 'neural'">HQ</span></span>
          </span>
        </div>

        <!--
          Checkmark, only display for selected option.

          Highlighted: "text-white", Not Highlighted: "text-blue-600"
        -->
        <span class="text-blue-600 absolute inset-y-0 right-0 flex items-center pr-4" v-if="voiceId(selected) !== voiceId(item)">
          <button type="button" @click="select(item)" class="btn-sm py-1">{{$t('ui.select')}}</button>
        </span>
        <span class="text-blue-600 absolute inset-y-0 right-0 flex items-center pr-4" v-if="voiceId(selected) === voiceId(item)">
          <!-- Heroicon name: solid/check -->
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
          </svg>
        </span>
      </li>
      <div class="hidden">
        <audio ref="player" @ended="clearPlayer" controls="controls" id="audioPreview" style="display:none">
          <source :src="url" type="audio/mp3" />
        </audio>
      </div>
      <!-- More items... -->
    </ul>
  </div>
</template>
<script>
export default {
  name: 'CustomSelect',
  props: {
    selected: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      url: null,
      playing: null,
    };
  },
  computed: {
    voiceUrl() {
      return (voice) => {
        const url = `${this.$appConfig.samplesUrl}/${voice.sampleFilename}`;
        // const platform = voice.platform === 'amazon-polly' ? 'polly' : 'gtts';
        // const isNeural = voice.isHQ ? 'neural' : 'standard';
        // if (platform === 'gtts') return `${this.$appConfig.samplesUrl}/${platform}-${isNeural}-${voice.name}-${voice.languageCode}.mp3`;
        return url;
      };
    },
    voiceId() {
      return (item) => {
        const kind = item.ttsVoiceKind ? `-${item.ttsVoiceKind}` : '';
        return `${item.ttsVoiceName}-${item.mainLanguage}${kind}`;
      };
    },
    itemsWithId() {
      if (!this.items) return [];
      return this.items.map((el) => ({ ...el, name: el.id }));
    },
  },
  methods: {
    close() {
      this.show = false;
      const audio = this.$refs.player;
      this.playing = null;
      this.url = null;
      audio.pause();
    },
    select(item) {
      // console.log(item);
      this.$emit('update:selected', item);
      this.close();
    },
    clearPlayer() {
      this.playing = null;
      this.url = null;
      // console.log('player cleared');
    },
    play(item) {
      this.playing = item.id;
      this.url = item.url;
      this.$refs.player.player.stop();
      this.$refs.player.player.play();
    },
    stop() {
      this.$refs.player.player.stop();
      this.playing = null;
      this.url = null;
    },
    previewAudio(item, index) {
      this.playing = index;
      this.url = this.voiceUrl(item);
      const audio = this.$refs.player;
      // console.log('audio', audio);

      // audio.attr('src', item.url);
      audio.load(); // call this to just preload the audio without playing
      audio.play(); // call this to play the song right away
    },
  },
};
</script>
