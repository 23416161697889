<template>
    <div>
      <div class=''>
        <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0" aria-label="Pagination">
          <div class="flex -mt-px w-0 flex-1" :class='props.theme.firstPage' @click="props.setFirstPage">
            <a  v-if="props.hasEdgeNav" v-bind="{...props.aProps,...props.firstPageProps}" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-200">
              <svg class="mr-3 h-5 w-5 text-gray-400" x-description="Heroicon name: solid/arrow-narrow-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path>
              </svg>
              {{$t('ui.first')}}
            </a>
          </div>
          <div class=" md:-mt-px flex" v-show="props.showPagination" :class="props.theme.list">
            <a :class="props.theme.prev" @click="props.setPrevPage" href="#" class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
              <svg xmlns="http://www.w3.org/2000/svg" class="mr-3 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
              </svg>
            </a>
            <a v-for="page in props.pages"
              v-bind="props.aProps" :class="props.pageClasses(page)"
              v-on="props.pageEvents(page)"
              :key="page" href="#" class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200 border-t-2 pt-4 px-2 md:px-4 inline-flex items-center text-sm font-medium">
              {{page}}
            </a>
            <a href="#" :class="props.theme.next" @click="props.setNextPage"  class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
              <svg xmlns="http://www.w3.org/2000/svg" class="ml-3 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
              </svg>
            </a>
          </div>
          <div class="flex -mt-px w-0 flex-1 justify-end" :class="props.theme.lastPage" @click="props.setLastPage">
            <a v-if="props.hasEdgeNav" v-bind="{...props.aProps, ...props.lastPageProps}" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-200">
              {{$t('ui.last')}}
              <svg class="ml-3 h-5 w-5 text-gray-400" x-description="Heroicon name: solid/arrow-narrow-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
              </svg>
            </a>
          </div>
        </nav>
      </div>
      <div class="text-center mt-4"><p v-show="props.hasRecords" :class='props.theme.count'>{{props.count}}</p></div>
    </div>
</template>

<script>
export default {
  name: 'MyPagination',
  props: ['props'],
};
</script>
