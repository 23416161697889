import { Auth } from 'aws-amplify';

export default (to, from, next) => {
  // // console.log('guard', to, from, next);
  Auth.currentSession()
    .then((data) => {
      localStorage.setItem('token', data.accessToken.jwtToken);
      const userGroups = data.accessToken.payload['cognito:groups'];
      const isSuperAdmin = userGroups.indexOf('role:super-admin') > -1 || userGroups.indexOf('role:hm_su') > -1;

      console.log('guard', data, to, isSuperAdmin);
      next();
    })
    .catch(() => {
      const path = '/login';
      const redirectRoute = {
        path,
        query: {
          redirect: to.fullPath,
        },
      };
      next(redirectRoute);
      // next();
    });
};
