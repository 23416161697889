<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="250" width="250"><path stroke="CurrentFill" d="M0.959867 10.2685C1.114 11.7092 2.2727 12.8679 3.71266 13.0284C4.78221 13.1476 5.88037 13.25 7 13.25C8.11963 13.25 9.21779 13.1476 10.2873 13.0284C11.7273 12.8679 12.886 11.7092 13.0401 10.2685C13.1539 9.20502 13.25 8.11315 13.25 7C13.25 5.88684 13.1539 4.79498 13.0401 3.73147C12.886 2.29082 11.7273 1.13211 10.2873 0.971609C9.21779 0.852392 8.11963 0.75 7 0.75C5.88037 0.75 4.78221 0.852392 3.71266 0.971609C2.2727 1.13211 1.114 2.29082 0.959867 3.73147C0.846083 4.79498 0.75 5.88684 0.75 7C0.75 8.11315 0.846084 9.20502 0.959867 10.2685Z"></path><path stroke="CurrentFill" stroke-linecap="round" stroke-linejoin="round" d="M4 4.5H7"></path><path stroke="CurrentFill" stroke-linecap="round" stroke-linejoin="round" d="M10 9.5H7"></path><path stroke="CurrentFill" stroke-linecap="round" stroke-linejoin="round" d="M8.5 6C9.32843 6 10 5.32843 10 4.5C10 3.67157 9.32843 3 8.5 3C7.67157 3 7 3.67157 7 4.5C7 5.32843 7.67157 6 8.5 6Z"></path><path stroke="CurrentFill" stroke-linecap="round" stroke-linejoin="round" d="M5.5 11C4.67157 11 4 10.3284 4 9.5C4 8.67157 4.67157 8 5.5 8C6.32843 8 7 8.67157 7 9.5C7 10.3284 6.32843 11 5.5 11Z"></path></svg>

</template>

<script>
export default {
  name: 'SettingsIcon',
  props: {
    title: {
      type: String,
      default: 'voice id user',
    },
  },
};
</script>
