<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="250" width="250"><path stroke="currentColor" fill="black" stroke-linejoin="round" d="M5.39885 11.5189C4.73631 11.4866 4.06406 11.4315 3.41681 11.3651L0.75 12L1.36839 9.15545C1.27181 8.16346 1.20057 7.11791 1.20057 6.15C1.20057 4.97706 1.30517 3.69223 1.43271 2.526C1.52104 1.71822 2.15486 1.07866 2.96229 0.987153C4.13464 0.854295 5.42076 0.75 6.60054 0.75C7.78043 0.75 9.06758 0.854315 10.2405 0.987193C11.048 1.07866 11.6818 1.71822 11.7698 2.52604C11.8736 3.47891 11.9618 4.51094 11.9906 5.49643"></path><path stroke="currentColor" fill="black" stroke-linejoin="round" d="M11.6768 5.41865C10.8431 5.32417 9.92851 5.25 9.08954 5.25C8.25048 5.25 7.33516 5.32418 6.50104 5.41867C5.92685 5.48372 5.47613 5.93853 5.41356 6.51299C5.32322 7.34232 5.24945 8.25599 5.24945 9.09009C5.24945 9.9244 5.32326 10.8398 5.41362 11.6702C5.47614 12.2447 5.92686 12.6995 6.50111 12.7639C7.33521 12.8575 8.25051 12.9302 9.08954 12.9302C9.59444 12.9302 10.1267 12.9039 10.6523 12.8625L13.25 13.2502L12.8692 10.5466C12.906 10.0551 12.9296 9.55976 12.9296 9.09009C12.9296 8.25598 12.8552 7.3423 12.7645 6.51296C12.7017 5.93853 12.251 5.48372 11.6768 5.41865Z"></path></svg>
</template>

<script>
export default {
  name: 'AliasIcon',
  props: {
    title: {
      type: String,
      default: 'voice id user',
    },
  },
};
</script>
